import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import Vue from 'vue';
import "swiper/swiper.min.css"

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


import './style/common.css'


createApp(App).use(router).use(ElementPlus).mount('#app')
